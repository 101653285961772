.logout-link {
	display: flex !important;
	align-items: center;
	span {
		line-height: 20px; } }

#header-profile-text {
	margin-right: 7px;
	font-size: 14px; }

#header-profile-email {
	font-size: 14px; }

#header-profile-submenu {
	line-height: 27px;
	&::after {
		vertical-align: 1px; } }

.sidebar-bar-icon {
	font-size: 14px; }

.logout-icon {
	font-size: 14px; }
