@import '../../../assets/sass/_vars';

body {
	&.form-edit-page {
		.footer-container {
			display: none; }
		.section-container {
			margin-bottom: 0 !important; }
		.content-wrapper {
			padding: 0; } }
	&.aside-collapsed {
		.form-editor-controls-header {
			width: calc(100% - 70px);
			left: 70px; } } }

#previewInputGroupCol {
	display: inline-flex;
	align-items: center; }

.color-picker-fg {
	position: relative;
	input {
		padding-right: 40px; }
	.node-color {
		position: absolute;
		bottom: 7px;
		right: 10px;
		display: inline-block;
		width: 20px;
		height: 20px;
		border: 1px solid #000; } }

.form-editor-card {
	margin-top: 62px;
	.card-body {
		padding-top: 0;
		padding-bottom: 0; } }

.form-editor-controls-header {
	position: fixed;
	top: 57px;
	left: 220px;
	width: calc(100% - 220px);
	background-color: #F7F7F7 !important;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0 !important;
	z-index: 20;
	@include mt(.4s); }

.form-wysivig-editor-wrapper {
	.rdw-image-wrapper, .rdw-colorpicker-wrapper, .rdw-emoji-wrapper, .rdw-link-wrapper, .rdw-embedded-wrapper {
		position: unset; }
	.rdw-editor-toolbar {
		position: relative; }
	.popup-editor {
		top: 105%;
		left: 50% !important;
		transform: translateX(-50%); } }

.edit-form-title {
	line-height: 35px; }

.preview-form {
	display: flex;
	align-items: flex-start;
	justify-content: space-between; }

.form-controls-col {
	height: calc(100vh - 114px);
	background-color: $gray-lighter;
	padding: 0 !important; }

.form-controls-wrapper {
	padding: 20px; }

.formBgImageInput {
	.custom-file-label {
		white-space: nowrap;
		overflow: hidden; } }

.card-form-col {
	padding: 0 !important;
	height: calc(100vh - 114px);
	&.mobile-format {
		background-image: url(../../../assets/img/mobile-phone-screen.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 45.5%;
		background-size: 370px; } }

.card-form-col {
	&.mobile-format {
		display: flex;
		align-items: center;
		justify-content: center;
		.slimScrollDiv {
			width: 320px !important;
			height: 602px !important; } } }

.card-form-scroll-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	&.mobile-format {
		width: 320px !important;
		height: 602px !important; }
	&.top-bar {
		align-items: flex-start; }
	&.bottom-bar {
		align-items: flex-end; }
	&.popup {
		padding-top: 20px; } }

.form-card-scroll-wrapper {
	height: unset; }

.cpicker-trigger {
	display: inline-block;
	padding: 0 5px;
	cursor: pointer;
	border: 1px solid #656565;
	color: #fff; }

.form-card {
	max-width: 100%;
	max-height: 100%;
	height: auto;
	margin: 0;
	min-width: 320px;
	width: 500px;
	max-width: 100%;
	overflow: hidden;
	&.mobile-format {
		.slimScrollDiv {
			height: unset !important; } }
	.form-card-body {
		position: relative;
		border: 1px solid $slate-grey;
		border-radius: 22px;
		background-color: transparent;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		text-align: center;
		color: $slate-grey;
		padding: 20px;
		height: 100%; }
	&.mobile-format {
		width: 320px !important;
		left: unset;
		margin: 0;
		padding: 0;
		border: none;
		.form-card-body {
			width: 100%; }
		&.popup {
			padding: 0 20px;
			align-items: center; }
		&.top-bar {
			.form-button {
				margin-left: 0; } }
		&.bottom-bar {
			.form-button {
				margin-left: 0; } }
		.form-title {
			font-size: 20px;
			margin-bottom: 10px; }
		.form-descr {
			line-height: 19px; }
		.form-button {
			padding: 0px; } }
	.form-control {
		&.form-button-input {
			padding: 0;
			background-color: transparent;
			border: none;
			color: #fff;
			overflow-y: auto;
			text-align: center; } }
	.card-body {
		flex: unset; }
	&.popup {
		position: relative;
		margin: 0 20px !important; }
	&.top-bar, &.bottom-bar {
		&:not(.mobile-format) {
			width: 100% !important;
			max-width: 100% !important;
			.form-card-body {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap; } }
		.form-title {
			margin-right: 20px; }
		.form-descr {
			margin-right: 20px;
			flex: 0 0 30%; }
		.form-button {
			margin-left: 20px; } }
	.form-title {
		padding: 0 5px;
		line-height: 35px;
		margin-bottom: 20px;
		cursor: pointer;
		&:hover {
			background-color: #EFE9E9;
			color: #656565 !important; } }
	.form-descr {
		padding: 0 5px;
		cursor: pointer;
		&:hover {
			background-color: #EFE9E9;
			color: #656565 !important; } }
	.form-close-icon {
		position: absolute;
		top: 15px;
		right: 15px;
		cursor: pointer; } }

@media only screen and (max-width : 1200px) {
	.card-form-col {
		&.mobile-format {
			background-position: 47.5% 45.5%; } }

	.form-card {
		&.top-bar, &.bottom-bar {
			.form-title {
				margin-right: 0;
				flex: 0 0 100%; }
			.form-descr {
				margin-right: 0;
				flex: 0 0 100%; }
			.form-button {
				margin-left: 0; }
			.form-input-wrap {
				flex: 0 0 100%; }
			.form-button-wrap {
				flex: 0 0 100%; } } }

	.form-editor-card {
		margin-top: 113px; }

	.form-controls-col, .card-form-col {
		height: calc(100vh - 168px); } }

@media only screen and (min-width : 992px) {
	body.form-edit-page {
		overflow: hidden;
		.wrapper {
			overflow: hidden; } } }

@media only screen and (max-width : 992px) {
	.card-form-col {
		&.mobile-format {
			background-position: 50% 45.5%; } }

	.form-editor-card {
		margin-top: 162px; }

	.card-form-col {
		height: 900px; }

	.form-controls-col {
		height: auto; }

	.preview-form {
		flex-wrap: wrap; }

	body.form-edit-page {
		.form-controls-col {
			height: auto; } }

	#previewInputGroupCol {
		flex: 0 0 100%; } }

@media only screen and (max-width : 768px) {
	body {
		&.aside-collapsed {
			.form-editor-controls-header {
				width: 100%;
				left: 0; }
			&.aside-toggled {
				.form-editor-controls-header {
					left: 0; } } } }

	.form-editor-card {
		margin-top: 0; }

	.form-editor-controls-header {
		position: relative;
		top: 0;
		width: 100%;
		left: 0; } }

@media only screen and (max-width : 480px) {
	.card-form-col {
		&.mobile-format {
			background-position: 49% 45.5%;
			background-size: 320px;
			padding: 0;
			margin-left: 0;
			margin-right: 0;
			.slimScrollDiv {
				height: 527px !important; } } }

	.card-form-scroll-wrapper {
		&.mobile-format {
			height: 527px !important; } }

	.form-card {
		&.mobile-format {
			&.top-bar, &.bottom-bar {
				padding: 0 20px; } } } }
