.exceptions-card-header {
	display: flex;
	align-items: flex-start;
	.exceptions-header {
		flex-shrink: 0;
		margin-right: 25px;
		h3 {
			margin-bottom: 0px;
			line-height: 30px; } } }

.exceptions-list-table {
	tr {
		td, th {
			user-select: none; } } }

.exceptions-search-form {
	width: 100%; }

.exceptions-controls-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	.control-item {
		display: inline-block;
		cursor: pointer;
		svg {
			font-size: 18px; }
		&:not(:last-child) {
			margin-right: 15px; } } }


@media only screen and (max-width : 768px) {
	.exceptions-card-header {
		flex-wrap: wrap;
		.exceptions-header {
			flex: 0 0 100%;
			margin: 0;
			margin-bottom: 20px;
			text-align: center; } } }

@media only screen and (max-width : 576px) {
	table {
		&.exceptions-list-table {
			tr {
				display: block;
				th, td {
					display: block;
					width: 100%;
					text-align: center;
					.exceptions-controls-list {
						text-align: center; } } } } }

	.export-checkboxes-group {
		.c-checkbox {
			display: block; } } }
