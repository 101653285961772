.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

@media (min-width: 480px) {
  .text-xs-left {
    text-align: left; }

  .text-xs-right {
    text-align: right; }

  .text-xs-center {
    text-align: center; } }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left; }

  .text-sm-right {
    text-align: right; }

  .text-sm-center {
    text-align: center; } }


@media (min-width: 768px) {
  .text-md-left {
    text-align: left; }

  .text-md-right {
    text-align: right; }

  .text-md-center {
    text-align: center; } }


@media (min-width: 992px) {
  .text-lg-left {
    text-align: left; }

  .text-lg-right {
    text-align: right; }

  .text-lg-center {
    text-align: center; } }


@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left; }

  .text-xl-right {
    text-align: right; }

  .text-xl-center {
    text-align: center; } }
