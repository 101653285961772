/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.graph-filter-controls {
		flex-wrap: wrap;
		justify-content: center; } }


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (max-width : 576px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	.navbar-header {
		display: none; } }
