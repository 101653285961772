.formlist-table {
	tr {
		td, th {
			user-select: none; } } }

.form-controls-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	.control-item {
		display: inline-block;
		cursor: pointer;
		svg {
			font-size: 18px; }
		&:not(:last-child) {
			margin-right: 15px; } } }

@media only screen and (max-width : 576px) {
	table {
		&.formlist-table {
			tr {
				display: block;
				th, td {
					display: block;
					width: 100%;
					text-align: center;
					.form-controls-list {
						text-align: center; } } } } } }
