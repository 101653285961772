.pay-visits-form {
	display: flex;
	align-items: flex-start;
	.pay-visit-btn {
		margin-top: 27px;
		margin-left: 20px; } }

.payment-history-table {
	tr {
		td, th {
			user-select: none; } } }


@media only screen and (max-width : 768px) {
	table {
		&.payment-history-table {
			tr {
				display: block;
				th, td {
					display: block;
					width: 100%;
					text-align: center; } } } } }

