.payment-visits-packages-table {
	margin: 0;
	padding: 0;
	list-style-type: none;
	.control-item {
		display: inline-block;
		cursor: pointer;
		svg {
			font-size: 18px; }
		&:not(:last-child) {
			margin-right: 15px; } } }

.add-visits-user-form {
	.add-visits-user-btn {
		margin-top: 29px; } }


@media only screen and (max-width : 1200px) {
	.add-visits-user-form {
		.add-visits-user-btn {
			margin-top: 10px; } } }

@media only screen and (max-width : 768px) {
	table {
		&.payment-visits-packages-table {
			tr {
				display: block;
				th, td {
					display: block;
					width: 100%;
					text-align: center;
					.payment-visits-packages-controls-list {
						text-align: center; } } } } } }
