.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse; }

.justify-content-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.justify-content-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.justify-content-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.align-items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.align-items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; }

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.align-items-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline; }

.align-items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }

.align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start; }

.align-content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end; }

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center; }

.align-content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between; }

.align-content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around; }

.align-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch; }

.align-self-auto {
  -ms-flex-item-align: auto;
  align-self: auto; }

.align-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start; }

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end; }

.align-self-center {
  -ms-flex-item-align: center;
  align-self: center; }

.align-self-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline; }

.align-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch; }


@media (min-width: 480px) {
  .flex-xs-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }

  .flex-xs-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

  .flex-xs-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }

  .flex-xs-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }

  .flex-xs-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

  .flex-xs-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }

  .flex-xs-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse; }

  .justify-content-xs-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }

  .justify-content-xs-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }

  .justify-content-xs-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

  .justify-content-xs-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

  .justify-content-xs-around {
    -ms-flex-pack: distribute;
    justify-content: space-around; }

  .align-items-xs-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }

  .align-items-xs-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; }

  .align-items-xs-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }

  .align-items-xs-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline; }

  .align-items-xs-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }

  .align-content-xs-start {
    -ms-flex-line-pack: start;
    align-content: flex-start; }

  .align-content-xs-end {
    -ms-flex-line-pack: end;
    align-content: flex-end; }

  .align-content-xs-center {
    -ms-flex-line-pack: center;
    align-content: center; }

  .align-content-xs-between {
    -ms-flex-line-pack: justify;
    align-content: space-between; }

  .align-content-xs-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around; }

  .align-content-xs-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch; }

  .align-self-xs-auto {
    -ms-flex-item-align: auto;
    align-self: auto; }

  .align-self-xs-start {
    -ms-flex-item-align: start;
    align-self: flex-start; }

  .align-self-xs-end {
    -ms-flex-item-align: end;
    align-self: flex-end; }

  .align-self-xs-center {
    -ms-flex-item-align: center;
    align-self: center; }

  .align-self-xs-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline; }

  .align-self-xs-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch; } }


@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }

  .flex-sm-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse; }

  .justify-content-sm-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }

  .justify-content-sm-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }

  .justify-content-sm-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

  .justify-content-sm-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

  .justify-content-sm-around {
    -ms-flex-pack: distribute;
    justify-content: space-around; }

  .align-items-sm-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }

  .align-items-sm-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; }

  .align-items-sm-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }

  .align-items-sm-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline; }

  .align-items-sm-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }

  .align-content-sm-start {
    -ms-flex-line-pack: start;
    align-content: flex-start; }

  .align-content-sm-end {
    -ms-flex-line-pack: end;
    align-content: flex-end; }

  .align-content-sm-center {
    -ms-flex-line-pack: center;
    align-content: center; }

  .align-content-sm-between {
    -ms-flex-line-pack: justify;
    align-content: space-between; }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around; }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch; }

  .align-self-sm-auto {
    -ms-flex-item-align: auto;
    align-self: auto; }

  .align-self-sm-start {
    -ms-flex-item-align: start;
    align-self: flex-start; }

  .align-self-sm-end {
    -ms-flex-item-align: end;
    align-self: flex-end; }

  .align-self-sm-center {
    -ms-flex-item-align: center;
    align-self: center; }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline; }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch; } }


@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }

  .flex-md-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }

  .flex-md-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse; }

  .justify-content-md-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }

  .justify-content-md-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }

  .justify-content-md-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

  .justify-content-md-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

  .justify-content-md-around {
    -ms-flex-pack: distribute;
    justify-content: space-around; }

  .align-items-md-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }

  .align-items-md-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; }

  .align-items-md-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }

  .align-items-md-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline; }

  .align-items-md-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }

  .align-content-md-start {
    -ms-flex-line-pack: start;
    align-content: flex-start; }

  .align-content-md-end {
    -ms-flex-line-pack: end;
    align-content: flex-end; }

  .align-content-md-center {
    -ms-flex-line-pack: center;
    align-content: center; }

  .align-content-md-between {
    -ms-flex-line-pack: justify;
    align-content: space-between; }

  .align-content-md-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around; }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch; }

  .align-self-md-auto {
    -ms-flex-item-align: auto;
    align-self: auto; }

  .align-self-md-start {
    -ms-flex-item-align: start;
    align-self: flex-start; }

  .align-self-md-end {
    -ms-flex-item-align: end;
    align-self: flex-end; }

  .align-self-md-center {
    -ms-flex-item-align: center;
    align-self: center; }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline; }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch; } }


@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }

  .flex-lg-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse; }

  .justify-content-lg-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }

  .justify-content-lg-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }

  .justify-content-lg-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

  .justify-content-lg-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

  .justify-content-lg-around {
    -ms-flex-pack: distribute;
    justify-content: space-around; }

  .align-items-lg-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }

  .align-items-lg-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; }

  .align-items-lg-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }

  .align-items-lg-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline; }

  .align-items-lg-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }

  .align-content-lg-start {
    -ms-flex-line-pack: start;
    align-content: flex-start; }

  .align-content-lg-end {
    -ms-flex-line-pack: end;
    align-content: flex-end; }

  .align-content-lg-center {
    -ms-flex-line-pack: center;
    align-content: center; }

  .align-content-lg-between {
    -ms-flex-line-pack: justify;
    align-content: space-between; }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around; }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch; }

  .align-self-lg-auto {
    -ms-flex-item-align: auto;
    align-self: auto; }

  .align-self-lg-start {
    -ms-flex-item-align: start;
    align-self: flex-start; }

  .align-self-lg-end {
    -ms-flex-item-align: end;
    align-self: flex-end; }

  .align-self-lg-center {
    -ms-flex-item-align: center;
    align-self: center; }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline; }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch; } }


@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }

  .flex-xl-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse; }

  .justify-content-xl-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }

  .justify-content-xl-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }

  .justify-content-xl-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

  .justify-content-xl-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

  .justify-content-xl-around {
    -ms-flex-pack: distribute;
    justify-content: space-around; }

  .align-items-xl-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }

  .align-items-xl-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; }

  .align-items-xl-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }

  .align-items-xl-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline; }

  .align-items-xl-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }

  .align-content-xl-start {
    -ms-flex-line-pack: start;
    align-content: flex-start; }

  .align-content-xl-end {
    -ms-flex-line-pack: end;
    align-content: flex-end; }

  .align-content-xl-center {
    -ms-flex-line-pack: center;
    align-content: center; }

  .align-content-xl-between {
    -ms-flex-line-pack: justify;
    align-content: space-between; }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around; }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch; }

  .align-self-xl-auto {
    -ms-flex-item-align: auto;
    align-self: auto; }

  .align-self-xl-start {
    -ms-flex-item-align: start;
    align-self: flex-start; }

  .align-self-xl-end {
    -ms-flex-item-align: end;
    align-self: flex-end; }

  .align-self-xl-center {
    -ms-flex-item-align: center;
    align-self: center; }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline; }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch; } }
