.admin-users-card-header {
	display: flex;
	align-items: center;
	.header-btns-control {
		display: flex;
		align-items: center;
		margin-left: 50px;
		text-align: right;
		.btn {
			white-space: nowrap; } } }

.admin-users-search-form {
	width: 100%; }

.admin-users-list-table {
	tr {
		td, th {
			user-select: none; } } }

.admin-users-controls-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	.control-item {
		display: inline-block;
		cursor: pointer;
		svg {
			font-size: 18px; }
		&:not(:last-child) {
			margin-right: 15px; } } }

@media only screen and (max-width : 768px) {
	table {
		&.admin-users-list-table {
			tr {
				display: block;
				th, td {
					display: block;
					width: 100%;
					text-align: center;
					.admin-users-controls-list {
						text-align: center; } } } } } }

