@import '../../assets/sass/_vars';


.subscriber-profile-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	.profile-header {
		margin-right: 25px;
		flex-shrink: 0;
		h3, h5 {
			margin: 0;
			line-height: 32px; } } }


.profile-subscribers-list-table {
	tr {
		td, th {
			user-select: none; } } }

.profile-subscribers-controls-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	.control-item {
		display: inline-block;
		cursor: pointer;
		svg {
			font-size: 18px; }
		&:not(:last-child) {
			margin-right: 15px; } } }

.timeline-wrapper {
	display: flex;
	.timeline-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			margin-bottom: 5px; } }
	.second-column {
		margin-left: 30px;
		padding-left: 30px;
		border-left: 1px solid $dim-grey;
		li {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 10px;
				left: -35px;
				width: 10px;
				height: 10px;
				border: 1px solid $dim-grey;
				border-radius: 100%;
				background-color: $light-grey; } } } }


@media only screen and (max-width : 992px) {
	.subscriber-profile-header {
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;
		.profile-header {
			flex: 0 0 100%; }
		.header-btns-control {
			flex: 0 0 100%;
			text-align: center;
			justify-content: center;
			margin-top: 20px;
			button {
				margin: 5px !important; } } } }


@media only screen and (max-width : 576px) {
	table {
		&.profile-subscribers-list-table {
			tr {
				display: block;
				th, td {
					display: block;
					width: 100%;
					text-align: center;
					.profile-subscribers-controls-list {
						text-align: center; } } } } } }
