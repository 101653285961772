@import '../../../assets/sass/vars';

body {
	&.trigger-edit-page {
		.footer-container {
			display: none; }
		.section-container {
			margin-bottom: 0 !important; }
		.content-wrapper {
			padding: 0; } }
	&.aside-collapsed {
		.trigger-edit-header {
			width: calc(100% - 70px);
			left: 70px; } } }

.trigger-edit-header {
	position: fixed;
	top: 57px;
	left: 220px;
	width: calc(100% - 220px);
	background-color: #EDEFF2 !important;
	@include mt(.5s);
	z-index: 50; }

.trigger-editor-card {
	margin-top: 81px; }

.trigger-edit-title {
	line-height: 20px;
	margin-bottom: 0; }

.main-template-editor {
	width: 100% !important;
	height: calc(100% - 30px) !important; }

.main-template-editor-wrap {
	position: relative;
	height: calc(100% - 30px) !important; }

.main-template-shortcodes-dropdown {
	position: absolute !important;
	top: 10px;
	right: 29px;
	z-index: 10; }

.template-render {
	width: 100%;
	height: calc(100% - 60px);
	margin-top: 60px;
	box-shadow: 3px 4px 26px -9px $slate-grey;
	overflow-y: auto;
	overflow-x: hidden; }

.template-editor-col {
	height: calc(100vh - 169px); }

.template-render-col {
	height: calc(100vh - 169px); }

.letter-subject-title-fg {
	position: relative;
	input {
		padding-right: 37px; }
	.emoji-picker-trigger {
		position: absolute;
		bottom: 5px;
		right: 10px;
		cursor: pointer;
		svg {
			font-size: 18px;
			color: $gold; } }
	.emoji-mart {
		position: absolute;
		top: 100%;
		right: 0;
		max-width: 100%;
		z-index: 5; } }


@media only screen and (min-width : 1200px) {
	body.trigger-edit-page {
		overflow: hidden;
		.wrapper {
			overflow: hidden; } } }

@media only screen and (max-width : 992px) {
	.trigger-editor-card {
		margin-top: 152px; } }

@media only screen and (max-width : 768px) {
	body {
		&.aside-collapsed {
			.trigger-edit-header {
				width: 100%;
				top: 65px;
				left: 0; }
			&.aside-toggled {
				.trigger-edit-header {
					left: 0;
					top: 65px; } } } } }

@media only screen and (max-width : 480px) {
	.trigger-editor-card {
		padding-top: 35px; } }
