.form-control {
	&.form-name-input {
		background-color: transparent;
		border: none;
		font-weight: 900;
		font-size: 25px; } }

.copy-code-fg {
	display: flex;
	align-items: flex-start;
	label {
		flex: 0 0 45%; }
	.copy-btn {
		white-space: nowrap; } }

#showPerItemSelectCol {
	padding-top: 29px; }

#showPerItemSelect {
	width: 100%;
	margin-left: 10px; }

#showPerItemSelectColLabel {
	line-height: 30px; }


@media only screen and (max-width : 1257px) {
	#showPerItemSelectCol {
		padding-top: 51px; } }

@media only screen and (max-width : 1200px) {
	#showPerItemSelectCol {
		padding-top: 30px; } }

@media only screen and (max-width : 576px) {
	.showing-form-filter {
		tr {
			display: block;
			th, td {
				display: block;
				width: 100%;
				text-align: center; } } }

	.copy-code-fg {
		flex-wrap: wrap;
		label {
			flex: 0 0 100%; } }

	#showPerItemSelectCol {
		padding-top: 20px; } }
