// Inter font
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 100;
	font-display: auto;
	src: url("../fonts/Inter/Inter-Thin.otf"); }

@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 100;
	font-display: auto;
	src: url("../fonts/Inter/Inter-ThinItalic.otf"); }


@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 200;
	font-display: auto;
	src: url("../fonts/Inter/Inter-ExtraLight.otf"); }
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 200;
	font-display: auto;
	src: url("../fonts/Inter/Inter-ExtraLightItalic.otf"); }

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 300;
	font-display: auto;
	src: url("../fonts/Inter/Inter-Light.otf"); }
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 300;
	font-display: auto;
	src: url("../fonts/Inter/Inter-LightItalic.otf"); }

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url("../fonts/Inter/Inter-Regular.otf"); }

@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 400;
	font-display: auto;
	src: url("../fonts/Inter/Inter-Italic.otf"); }


@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-display: auto;
	src: url("../fonts/Inter/Inter-Medium.otf"); }
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 500;
	font-display: auto;
	src: url("../fonts/Inter/Inter-MediumItalic.otf"); }

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-display: auto;
	src: url("../fonts/Inter/Inter-SemiBold.otf"); }
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 600;
	font-display: auto;
	src: url("../fonts/Inter/Inter-SemiBoldItalic.otf"); }

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-display: auto;
	src: url("../fonts/Inter/Inter-Bold.otf"); }

@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 700;
	font-display: auto;
	src: url("../fonts/Inter/Inter-BoldItalic.otf"); }


@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 800;
	font-display: auto;
	src: url("../fonts/Inter/Inter-ExtraBold.otf"); }
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 800;
	font-display: auto;
	src: url("../fonts/Inter/Inter-ExtraBoldItalic.otf"); }

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url("../fonts/Inter/Inter-Bold.otf"); }
@font-face {
	font-family: 'Inter';
	font-style: italic;
	font-weight: 900;
	font-display: auto;
	src: url("../fonts/Inter/Inter-BoldItalic.otf"); }

// Ubuntu

